<template>
<div>
  <div class="container-fluid">
    <PageTitle />
  
    <div 
      v-if="isList" 
      class="card"
    >
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6 mr-auto">
            <h5 class="card-title">{{pageTitle}} List</h5>
          </div>
          <div class="col-md-2">
            <div class="d-block m-b-10">
              <select 
                class="form-control custom-select" 
                @change="doFilter()" 
                v-model="filter.status"
              >
                <option value="0" selected>-- Filter Status --  </option>
                <option value="Y">Active</option>
                <option value="N">Inactive </option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <form @submit.prevent="doFilter(),doSearch()">
              <div class="form-group mb-0">
                <div class="input-group">
                  <input v-model="filter.search" @input="checkDisabled" type="text" class="form-control"
                    placeholder="Type and Press Enter" />
                  <div class="input-group-append">
                    <button disabled id="btn-search" class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                  </div>
                  <button v-if="isSearch" v-tooltip="'Clear Search Results'" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="table-responsive">
          <table class="table product-overview table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Level Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
              <tr v-for="(v,k) in data.data" :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td><span v-tooltip="v.bul_updated_date ? 'Last Updated '+ v.bul_updated_date.dates('format2') : 'Created '+ v.bul_created_date.dates('format2')">{{ v.bul_name }}</span></td>
                <td><LabelStatus :status="v[statusKey]" /></td>
                <td>
                  <router-link 
                    :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                    class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                    <i class="ti-marker-alt"></i>
                  </router-link> 
                  <a 
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon" v-tooltip="'Change Status'"
                    @click="doChangeStatusLevel(k,v)">
                    <i class="ti-settings"></i>
                  </a> 
                  <a 
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon" 
                    v-tooltip="'Delete'"
                    @click="doDeleteLevel(k,v)">
                    <i class="ti-trash"></i>
                  </a>
                </td>
              </tr>
              <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
            </tbody>
          </table>
      </div>
      <div class="card-footer" v-if="data.data!==false&&data.data.length">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="pagination-flat float-right">
              <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                <span slot="next-nav"><i class="icon-arrow-right"></i></span>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doPost)">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    Access Level Name 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.bul_name"
                    type="text" 
                    class="form-control" 
                    placeholder="Access Level Name" 
                  /> 
                  <VValidate name="Level Access Name" v-model="row.bul_name" :rules="mrValidation.bul_name" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    Menu Role 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <div @click="menuHeight">
                    <vSelect multiple 
                      class="vs__multiple"
                      v-model="row.bul_menu_role" 
                      placeholder="Select Menu Access"
                      :options="mrMenuRel" 
                      :reduce="v=>v.value" 
                    ></vSelect>
                  </div>
                  <VValidate name="Menu Role" :value="(row.bul_menu_role ? (row.bul_menu_role.length||null) : null)" :rules="mrValidation.bul_menu_role" />
                </div>
              </div>
              <div class="col-md-12">     
                <hr/>
                <div class="form-group">
                  <label class="control-label">
                    Status 
                    <span class="text-danger mr5">*</span> 
                  </label>
                  <div>
                    <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                    <InputRadio v-model="row[statusKey]" name="status" option="N" />
                  </div>
                  <VValidate name="Status" v-model="row[statusKey]" :rules="mrValidation[statusKey]" />
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                  <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import Gen from '@libs/Gen'
let $ = global.jQuery

export default{
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
      idKey:'bul_id',
      statusKey:'bul_is_active',
      mrModuleRel:[],
      mrMenuRel:[],
      isSearch : false,
      total_data: 0
    }
  },
  methods: {
    doSearch (){
        this.isSearch = true
        $('#btn-search').attr("disabled", "disabled")
    },
    clsSearch(){
        this.isSearch = false
        this.filter["status"] = 0
        setTimeout(() => {
          $('#btn-search').attr("disabled", "disabled")
        }, 0)
    },
    doReset(){
      this.filter={}
      this.$router.push({name:this.$route.name,query:{page:1}});
      setTimeout(() => {
        $('#btn-search').attr("disabled", "disabled")
      }, 0)
      
    },
    doPost(){
      if(this.isAdd){
        this.row["bul_created_by"] = this.user.id
        this.doSubmitCRUD();
      }
      else{
        this.row["bul_updated_by"] = this.user.id
        this.doSubmitCRUD();
      }
    },
    menuHeight(){
      $(".vs__dropdown-menu").css({"max-height": "165px"})
    },
    checkDisabled() {
      if (this.filter.search.length <= 0) {
        $('#btn-search').attr("disabled", "disabled")
      } else {
        $('#btn-search').removeAttr("disabled")
      }
    },
    doDeleteLevel(k,v){

      // if(v[this.idKey] == this.user.levelId){
      //   global.Swal.fire({
      //     title: 'This Level Already Has Users',
      //     icon: 'warning',
      //   })
      // }
      // else{
      //   this.doDelete(k,v)
      // }
      Gen.apiRest(
        `/get/AvailUser/${v[this.idKey]}`
      )
        .then(res => {
          let status =res.data.status
          if(status === "ex"){
            global.Swal.fire({
              title: 'You Can\'t Delete This Level!',
              text: 'This level already has a list of users',
              icon: 'warning',
            })
          }
          else{
            this.doDelete(k,v)
          }
        })
    },
    doChangeStatusLevel(k,v){
      Gen.apiRest(
        `/get/AvailUser/${v[this.idKey]}`
      )
        .then(res => {
          let status =res.data.status
          if(status === "ex"){
            global.Swal.fire({
              title: 'You Can\'t Change Status This Level!',
              text: 'This level already has a list of users',
              icon: 'warning',
            })
          }
          else{
            this.doChangeStatus(k,v)
          }
        })
    }
  },
  mounted(){
    setTimeout(() => {
      $('.vs__open-indicator').attr('type', 'button')
    }, 300)
    this.apiGet()
    this.filter["status"] = 0
    setTimeout(() => {
      if(this.isList){
        if(this.$route.query.search){        
          if (this.filter && this.filter.search.length <= 0) {
            this.isSearch = false
            $('#btn-search').attr("disabled", "disabled")
          } else {
            this.isSearch = true
            $('#btn-search').removeAttr("disabled")
          }  
        }
      }
    },300)
  },
  watch:{
    $route(){
      this.apiGet()

      setTimeout(() => {
        $('.vs__open-indicator').attr('type', 'button')
      }, 0)
    }
  }
}
</script>